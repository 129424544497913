.grid-style {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;

}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
}

@media (max-width: 762px) {
    .grid-style {
     grid-template-columns: 1fr !important;
    }

    .grid-2 {
        grid-template-columns: 1fr;
    }

    .flex-dir-sm-column {
        flex-direction: column !important;
    }
 }

